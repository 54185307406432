let columns = []

// if (localStorage.contractsColumns) {
//   JSON.parse(localStorage.contractsColumns).forEach((col, i) => {
//     if (columns[i].name === col.name) {
//       columns[i].hidden = !col.show
//     }
//   })
// }

// if (localStorage.contractsClientColumns) {
//   JSON.parse(localStorage.contractsClientColumns).forEach((col, i) => {
//     if (columnsClient[i].name === col.name) {
//       columnsClient[i].hidden = !col.show
//     }
//   })
// }

export default {
  page: 0,
  perPage: 10,
  period: [
    new Date(2019, 0, 1),
    new Date(Date.now())
  ],
  sort: {
    field: 'date',
    order: 'desc'
  },
  filter: {
    status: 0
  },
  view: 'table',
  sort2: null,
  sortDate: null,
  columnModal: false,
  columns: columns
}
