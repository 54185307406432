let columns = [
  { label: 'Аватар', field: 'avatar', hidden: false },
  { label: 'ФИО', field: 'name', hidden: false },
  { label: 'Дата рождения', field: 'birthday', hidden: false },
  { label: 'Должность', field: 'post', hidden: false },
  { label: 'Внутренний номер', field: 'workphone', hidden: false },
  { label: 'Личный номер телефона', field: 'personal_phone', hidden: false },
  { label: 'Раб. номер телефона', field: 'additional_phone', hidden: false },
  { label: 'Email', field: 'email', hidden: false },
  { label: 'Отдел', field: 'department', hidden: false },
  { label: 'Подразделение', field: 'subdivision', hidden: false },
  { label: 'Адрес подразделения', field: 'address', hidden: true }
]

if (localStorage.directoryColumns) {
  JSON.parse(localStorage.directoryColumns).forEach((col, i) => {
    if (columns[i].name === col.name) {
      columns[i].hidden = !col.show
    }
  })
}

export default {
  page: 0,
  perPage: 200,
  showFilter: true,
  sort: {
    field: 'name',
    order: 'asc'
  },
  allDirectoryMeta: 0,
  filter: {
    name: '',
    department: '',
    subdivision: '',
    workphone: '',
    birthday: ''
  },
  columnModal: false,
  columns: columns
}
