const columns = [
  {
    label: 'Сотрудник',
    field: 'name',
    hidden: false
  },
  {
    label: 'Подразделение',
    field: 'groupname',
    hidden: false
  },
  {
    label: 'Создано сделок',
    field: 'leadsCreatedCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Количество сделок',
    field: 'leadsCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Завершено задач',
    field: 'completedTasks',
    type: 'number',
    tooltip: 'Завершенные задачи за указанный период',
    hidden: false
  },
  {
    label: 'Не завершенных задач',
    field: 'notCompletedTasks',
    type: 'number',
    hidden: false
  },
  {
    label: 'Просроченные задачи',
    field: 'delayedTasks',
    type: 'number',
    hidden: false
  },
  {
    label: 'Количество комментариев',
    field: 'noteCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Исходящих звонков',
    field: 'outcallCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Исходящих звонков(МТС)',
    field: 'mtsCallsCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Проведено встреч',
    field: 'meetCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Создано проектов',
    field: 'projectCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Сумма проектов',
    field: 'projectSumm',
    type: 'number',
    hidden: false
  },
  {
    label: 'Проектов на рассмотрении',
    field: 'decisionCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Сумма проектов на рассмотрении',
    field: 'decisionSumm',
    type: 'number',
    hidden: false
  },
  {
    label: 'Одобрено проектов',
    field: 'approvedCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Одобрено проектов на сумму',
    field: 'approvedSumm',
    type: 'number',
    hidden: false
  },
  {
    label: 'Отказано',
    field: 'deniedCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Отказано на сумму',
    field: 'deniedSumm',
    type: 'number',
    hidden: false
  },
  {
    label: 'Передано в лизинг',
    field: 'factCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Передано в лизинг на сумму',
    field: 'factSumm',
    type: 'number',
    hidden: false
  },
  {
    label: 'Конвертация создано/заведено',
    field: 'convLeadProject',
    type: 'percentage',
    hidden: false
  },
  {
    label: 'Конвертация заведено/отгружено',
    field: 'convProjectFact',
    type: 'percentage',
    hidden: false
  },
  {
    label: 'Конвертация создано/отгружено',
    field: 'convLeadFact',
    type: 'percentage',
    hidden: false
  },
  {
    label: 'Конвертация сумма создано/заведено',
    field: 'convSLeadProject',
    type: 'percentage',
    hidden: false
  },
  {
    label: 'Конвертация сумма заведено/отгружено',
    field: 'convSProjectFact',
    type: 'percentage',
    hidden: false
  },
  {
    label: 'Конвертация сумма создано/отгружено',
    field: 'convSLeadFact',
    type: 'percentage',
    hidden: false
  },
  {
    label: 'Не создано',
    field: 'notCreatedCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Не выгружено',
    field: 'notUnloadedCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Не подписано',
    field: 'notSignedCount',
    type: 'number',
    hidden: false
  },
  {
    label: 'Нет аванса',
    field: 'notAdvanceCount',
    type: 'number',
    hidden: false
  }
]

if (localStorage.analyticsColumns) {
  JSON.parse(localStorage.analyticsColumns).forEach((col, i) => {
    if (columns[i].name === col.name) {
      columns[i].hidden = !col.show
    }
  })
}

export default {
  columnModal: false,
  columns: columns
}
