<template>
  <div id="app">
    <template v-if="canShow">
      <div class="lsg-container" :class="{ 'layout': !isHidenHeadAndSideBar, '': isHidenHeadAndSideBar, 'no_grid': $route.path == '/auth' || $route.path == '/registration' || $route.path == '/lostpass' }">
        <lsg-side-bar v-if="!isHidenHeadAndSideBar"></lsg-side-bar>
        <mq-layout :mq="['sm', 'xsm', 'md', 'lg', 'xlg']" class="aside__bg" v-if="!isHidenHeadAndSideBar" :class="{ 'active': isSideBarExpanded }">
          <div @click="changeSideBarState"></div>
        </mq-layout>
        <div class="lsg-content" :class="{ 'content__wide': !isSideBarExpanded, 'lsg-content-wide': isHidenHeadAndSideBar }">
          <lsg-header @tourRepeat="tourRepeat" v-if="!isHidenHeadAndSideBar"></lsg-header>
          <main :class="{ 'lsg-content-main': !isHidenHeadAndSideBar, 'lsg-content-main-nonauth': isHidenHeadAndSideBar }">
            <router-view :key="$route.fullPath"></router-view>
          </main>
        </div>
      </div>
      <notifications :duration="5000" position="top right" group="lsg-notify" classes="notification"></notifications>
      <notifications style="margin-bottom: 80px" position="bottom right" group="lsg-notify-lead" classes="notification--lead"></notifications>
      <notifications :duration="-1" width=500 position="top center" group="lsg-notify-support" classes="notification--support">
        <template slot="body" slot-scope="{ item, close }">
          <div :class="item.type" :style="item.data.style || ''" class="vue-notification vue-notification-wrapper notification--support" @click.self="() => { testNotify(item.data.info); close() }">
            <div class="title" @click.self="() => { testNotify(item.data.info); close() }">
              <span @click.self="() => { testNotify(item.data.info); close() }">{{ item.title }}</span>
              <a class="close" @click="close">
                <i class="fa fa-fw fa-close"></i>
              </a>
            </div>
            <div v-html="item.text" @click.self="testNotify(item.data.info)"/>
          </div>
        </template>
      </notifications>
      <lsg-overlay v-if="isShowOverlay">
        {{ overlayText }}
      </lsg-overlay>
      <mq-layout :mq="['sm', 'xsm']" class="sidebar_bottom">
        <SideBarBottom/>
      </mq-layout>
      <CallComponent v-if="$store.state.isCalling"/>
      <ModalsComponent v-if="$store.state.me"/>
      <TourMenu ref="tour" />
      <DirectoryMenu v-if="$store.state.me && ($store.state.me.role === 'admin' || ['60b9c160d244fb050376f2c5', '620509f862194d88bf312556', '5f0d73200ffedb935485c9c0'].includes($store.state.me._id))" ref="directory" />
      <Employee v-if="$store.state.me && $store.state.me.role !== 'partner'" ref="employee"/>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import axios from 'axios'

const Header = () => import('./components/layouts/Header.vue')
const SideBar = () => import('./components/layouts/SideBar.vue')
const SideBarBottom = () => import('./components/layouts/SidebarBottom')
const LsgOverlay = () => import('./components/ui/LsgOverlay.vue')
const CallComponent = () => import('./components/layouts/CallComponent')
const ModalsComponent = () => import('./components/layouts/modals/Index')
const TourMenu = () => import('./components/layouts/TourMenu')
const DirectoryMenu = () => import('./components/layouts/DirectoryMenu')
const Employee = () => import('./components/layouts/Employee')

const BASE_URL = process.env.VUE_APP_HTTP

export default {
  components: {
    ModalsComponent,
    CallComponent,
    'lsg-header': Header,
    'lsg-side-bar': SideBar,
    'lsg-overlay': LsgOverlay,
    SideBarBottom,
    DirectoryMenu,
    TourMenu,
    Employee
  },
  data () {
    return {
      canShow: false,
      leadId: null,
      BASE_URL: BASE_URL
    }
  },
  beforeCreate () {
    // if (this.$route.meta.subscribe) {
    Promise.all([
      this.$store.dispatch('me', this).then(() => {
        if (this.$store.state.me.role === 'admin') {
          this.$store.dispatch('allTours', this)
        }
        if (['admin', 'headmanager'].includes(this.$store.state.me.role)) {
          this.$store.dispatch('diadocDocuments', this)
        }
        // if (this.$store.state.me.role !== 'partner' && this.$store.state.me.role !== 'agent') {
        //   this.$store.dispatch('allMemos', this)
        // }
        this.$store.dispatch('lastEvents', this)
      }),
      this.$store.dispatch('leadStatusList', this),
      this.$store.dispatch('allTags', this),
      this.$store.dispatch('allGroups', this),
      this.$store.dispatch('allUsers', this),
      this.$store.dispatch('allThemes', this),
      this.$store.dispatch('unprocessesAppeals', this),
      this.$store.dispatch('unprocessesSupports', this),
      this.$store.dispatch('allFaqResponsible', this),
      this.$store.dispatch('allDoTasksMeta', this)
    ]).finally(() => {
      this.canShow = true
      this.initSubscription()
    })
    // } else {
    //   this.$nextTick(() => {
    //     this.canShow = true
    //   })
    // }
  },
  computed: {
    isHidenHeadAndSideBar () {
      return !this.$store.state.authToken
    },
    isSideBarExpanded () {
      return this.$store.state.sideBarState === 'expanded'
    },
    isShowOverlay () {
      return this.$store.state.overlayShow
    },
    overlayText () {
      return this.$store.state.overlayText
    }
  },
  methods: {
    async testNotify (value) {
      const url = `${BASE_URL}/support/message`
      await axios.post(url, value)
        .then(() => {
          this.$notify({
            group: 'lsg-notify',
            text: 'Сообщение отправлено'
          })
        })
    },
    initSubscription () {
      if (this.$store.state.me) {
        const that = this
        const taskAdded = gql`subscription taskAdded($id: ID!) {
          taskAdded (responsible_user_id: $id) {
              text
          }
        }`
        const taskAddedObserver = this.$apollo.subscribe({
          query: taskAdded,
          variables: {
            id: that.$store.state.me._id
          }
        })
        taskAddedObserver.subscribe({
          next () {
            const audio = new Audio('http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3')
            audio.play()
            that.$notify({
              group: 'lsg-notify',
              text: 'У Вас новая задача'
            })
          }
        })

        const leadFormAdded = gql`subscription leadFormAdded {
          leadFormAdded
        }`
        const leadFormAddedObserver = this.$apollo.subscribe({
          query: leadFormAdded,
          variables: {}
        })
        leadFormAddedObserver.subscribe({
          next ({ data }) {
            const audio = new Audio('http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3')
            audio.play()
            that.$notify({
              group: 'lsg-notify-lead',
              title: 'Новая сделка с формы',
              text: `<a href='https://cabinet.leasing-trade.ru/trade/${data.leadFormAdded._id}'>Перейти в сделку</a>`,
              duration: -1
            })
          }
        })

        const incomeCall = gql`subscription incomeCall($workphone: String!){
          incomeCall (workphone: $workphone)
        }`
        const incomeCallObserver = this.$apollo.subscribe({
          query: incomeCall,
          variables: {
            workphone: that.$store.state.me.workphone
          }
        })
        incomeCallObserver.subscribe({
          next ({ data }) {
            that.$store.state.call = data.incomeCall
            that.$store.state.isCalling = true
            const audio = new Audio('http://soundbible.com/mp3/soundbible-person-whistling-at-girl-daniel_simon.mp3')
            audio.play()
          }
        })

        const newContract = gql`subscription newContract {
          newContract
        }`
        const newContractObserver = this.$apollo.subscribe({
          query: newContract,
          variables: {}
        })
        newContractObserver.subscribe({
          next ({ data }) {
            that.$store.state.tv.employee = data.newContract.input
          }
        })

        const newNotification = gql`subscription newNotification {
          newNotification
        }`
        const newNotificationObserver = this.$apollo.subscribe({
          query: newNotification,
          variables: {}
        })
        newNotificationObserver.subscribe({
          next ({ data }) {
            that.$store.state.tv.notification = data.newNotification
          }
        })
      }
    },
    changeSideBarState (e) {
      e.preventDefault()
      let value = this.isSideBarExpanded ? 'nonexpanded' : 'expanded'
      this.$store.dispatch({ type: 'setSideBarState', value: value })
    },
    tourRepeat () {
      this.$refs.tour.tourRepeat()
    }
  }
}
</script>

<style lang="stylus">
@import "~nprogress/nprogress.css"
@import "./assets/styles/index.styl"

.lsg-container
  +below(980px)
    display grid
    grid-template-columns 100px 1fr

  +below(768px)
    display block

  &.no_grid
    display block

.lsg-content
  padding-left 280px
  padding-top: 80px;
  height 100%
  // max-height 100vh
  overflow-y auto
  // scrollbar-width none
  //position relative
  transition padding-left 0.3s
  z-index 1

  // &::-webkit-scrollbar
  //   width 0;
  //   height 0

  &-main
    min-height calc(100vh - 80px)

    &.lsg-content-wide
      padding-left 0
      padding-top 0

    &.lsg-nopadding
      padding 0

  +below(980px)
    padding-left 0
    padding-top 0
    // height 100%

  &.lsg-content-wide
    padding-left 0
    padding-top 0

.content__wide
  padding-left 100px

  +below(980px)
    padding-left 0

.aside__bg

  div
    fixed left top
    width 100%
    height 100vh
    background $dark
    opacity 0
    pointer-events none
    transition all 0.3s
    z-index 50

  &.active

    div
      opacity 0.5
      pointer-events auto

.header__search_mob
  padding 20px
  border-bottom 1px solid $graydark

  .hs
    position relative

    .form__item
      input
        height 45px

    &__drop
      position absolute
      left 0
      width 100%

.crm {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 -16px;
  width: 100%;

  +below(768px) {
    width: calc(100% + 42px);
  }

  +below(560px) {
    flex-direction: column;
  }

  +below(540px) {
    margin-bottom: 60px;
  }

  &__div {
    margin: 0 4px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  &__aside {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    height: calc(100vh - 195px);
    margin: 0 16px;
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    box-shadow: 0 7px 30px rgba($black, 0.06);
    border-radius: 10px;
    padding: 20px;
    overflow-y: auto;

    +below(768px) {
      max-width: 50%;
      margin-right: 0;
    }

    +below(560px) {
      max-width: 100%;
      height: auto;
      margin: 0 16px;
      box-sizing: border-box;
      width: auto;
    }
  }

  &__notes {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: calc(100% - 420px);
    width: 100%;
    margin: 0 -16px;
    position: relative;
    padding: 0 11px;

    +below(768px) {
      max-width: 50%;
      margin: 0;
      padding: 0;
    }

    +below(560px) {
      max-width: 100%;
    }

    &__overdue {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 10px 32px;
      absolute: left top;
      width calc(100% - 28px)
      background: $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      transform: translateY(-100%);
      margin: 0 14px;
      border-bottom: 1px solid $darkgray;
      box-shadow: 0 7px 30px rgba($black, 0.06);
      z-index: 1;

      +below(560px) {
        display: none;
      }

      &__icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &__div {
        margin-left: 20px;
        margin-right: 5px;
      }

      &__list {
        padding: 0;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: $c7A;
        margin: -4px 0 -4px 20px;

        li {
          margin: 4px 0;
        }
      }
    }

    &__send {
      background: none;
      border: none;
      absolute: right 16px bottom 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &__drag {
      background: none;
      border: none;
      absolute: right 60px bottom 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 !important
    }

    &__cancel {
      absolute: right 64px bottom 19px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $blue;
    }

    &__div {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: $c4E555A;
    }

    &__list {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      height: calc(100vh - 350px);
      overflow: hidden;
      padding: 0 16px 200px;
      margin-bottom: 8px;

      +below(560px) {
        height: auto;
        margin-top: 24px;
        padding-bottom: 10px;
      }
    }

    &__form {
      background: $white;
      border: 1px solid $darkgray;
      box-sizing: border-box;
      border-radius: 10px;
      margin: 0 16px;
      position: relative;

      &--deminished {
        margin: 0;
        border: 0;

        .mx-input {
          background: transparent !important;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0 14px;
        // height 1rem

        & .mx-input-wrapper input {
          background: transparent !important;
        }
      }

      &__body {
        textarea {
          border: none;
          resize: none;
          height: 100%;
          display: flex;
          border-radius: 0 0 10px 10px;
        }
      }

      select {
        border: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $blue;
        width: fit-content !important;
        appearance: none;
        background: none;
        padding: 6px 8px;
        height: auto;
        box-shadow: none !important;
        outline: none !important;
      }

      .mx-datepicker {
        width: auto;
      }

      .mx-input {
        border: none !important;
        height: auto !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 15px !important;
        color: $blue !important;
        width: fit-content !important;
        box-shadow: none !important;
      }

      .vs--single.vs--open {
        .vs__selected {
          position: relative;
        }
      }

      .v-select {
        width: fit-content;

        .vs__dropdown-toggle {
          padding: 0;
          border: none;
        }

        .vs__selected {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: $blue;
        }

        .vs__search {
          padding: 0;
          margin: 0;
          height: 100%;
        }
      }
    }

    &__type {
      display: inline-flex;
      position: relative;
      user-select: none;

      &__selected {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $blue;
        padding: 6px 8px;
        cursor: pointer;
      }

      &__list {
        absolute: left bottom;
        padding: 0;
        transform: translateY(100%);
        background: $white;
        border: 1px solid $pinky;
        box-sizing: border-box;
        box-shadow: 0 10px 30px rgba($c4E555A, 0.17);
        border-radius: 0 0 10px 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        z-index: 999;
        overflow: hidden;

        li {
          padding: 6px 8px;
          width: 100%;
          color: $c4E555A;
          background: $white;
          cursor: pointer;

          &:hover {
            background: $orange;
            color: $white;
          }
        }
      }
    }
  }

  &__note {
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    box-shadow: 0 7px 30px rgba($black, 0.06);
    border-radius: 10px;
    padding: 10px 20px;
    width: 100%;
    margin-bottom: 8px;
    flex-shrink: 0;

    &__close-outdated {
      display: flex;

      button {
        margin-left: 20px;
      }
    }

    &--red {
      background: rgba($red, 0.1);
      border: 1px solid rgba($darkgray, 0.3);
      width: calc(100% - 28px);
      margin: 10px auto 8px;

      .crm__note__date, .crm__note__manager, .crm__div, .crm__note__responsible {
        color: $red;
        font-weight: bold;
      }

      .crm__note__icon svg {
        path[stroke] {
          stroke: $red;
        }

        path[fill] {
          fill: $red;
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $c7A;
      padding-bottom: 10px;
      border-bottom: 1px solid $pinky;
      margin-bottom: 10px;

      &__info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      &__actions {
        display: flex;
        align-items: center;
        margin: 0 -6px;
      }
    }

    &__action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      padding: 0 6px;

      svg {
        width: 100%;
        height: auto;
      }
    }

    &__icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;

      svg {
        width: 100%;
        height: 100%;

        path[stroke] {
          stroke: $orange;
        }

        path[fill] {
          fill: $orange;
        }
      }

      &--green {
        svg {
          path[stroke] {
            stroke: $darkgreen;
          }

          path[fill] {
            fill: $darkgreen;
          }
        }
      }
    }

    &__date {
      margin-right: 15px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
      font-size: 14px;

      span, strong, p {
        font-size: 14px;
      }

      p .crm__note__text {
        margin-right: 4px;
      }
    }

    &__text {
      font-size: 0.9em;
      line-height: normal;
    }

    &__footer {
      padding-top: 10px;
      border-top: 1px solid $pinky;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: $c7A;

      .btn {
        padding: 6px 8px;
        min-height: auto;
        margin: 0 16px;
        border-radius: 5px;
      }
    }
  }
}

</style>
