let columns = [
  { hidden: false, label: 'Счётчик звонков', field: 'calls' },
  { hidden: false, label: 'Заявки', field: 'leads' },
  { hidden: false, label: 'Задачи', field: 'tasks' },
  { hidden: false, label: 'План', field: 'plan' },
  { hidden: false, label: 'Лимит', field: 'limit' },
  { hidden: false, label: 'Заведено проектов', field: 'projects' },
  { hidden: false, label: 'Проектов на рассмотрении', field: 'decision' },
  { hidden: false, label: 'Одобрено', field: 'approved' },
  { hidden: false, label: 'Передано в лизинг', field: 'fact_projects' },
  { hidden: false, label: 'Отказ СБ', field: 'denied' },
  { hidden: false, label: 'Забытые клиенты', field: 'forgotten' },
  { hidden: false, label: 'Не создано / Не выгружено', field: 'not_created' },
  { hidden: false, label: 'Не выгружено', field: 'not_unloaded' },
  { hidden: false, label: 'Не подписано / Нет аванса', field: 'not_signed' },
  { hidden: false, label: 'Нет аванса', field: 'not_advance' },
  { hidden: false, label: 'Заявки с формы', field: 'form' },
  { hidden: false, label: 'Истекает / Просрочено', field: 'expires' },
  { hidden: false, label: 'Просрочено', field: 'overdue' },
  { hidden: false, label: 'Неверные реквизиты', field: 'incorrect_details' },
  { hidden: false, label: 'Просрочено/не принято задач', field: 'cone_tasks' }
  // { hidden: false, label: 'Экстра бонус', field: 'extra_bonus' }
]

let clientColumns = [
  { hidden: false, label: 'Всего договоров', field: 'contracts' },
  { hidden: false, label: 'Действующие договоры', field: 'current_contracts' },
  { hidden: false, label: 'Одобренный лимит', field: 'approved_limit' },
  { hidden: false, label: 'FAQ', field: 'faq' },
  { hidden: false, label: 'Ближайший платёж', field: 'next_payment' },
  { hidden: false, label: 'Досрочное погашение', field: 'early_repayment' },
  { hidden: false, label: 'Рассчитать лизинг', field: 'calculate_leasing' },
  { hidden: false, label: 'Общая задолженность', field: 'debt' }
]

if (localStorage.widgetsColumns) {
  JSON.parse(localStorage.widgetsColumns).forEach((col, i) => {
    if (columns[i].name === col.name) {
      columns[i].hidden = !col.show
    }
  })
}

if (localStorage.clientWidgetsColumns) {
  JSON.parse(localStorage.clientWidgetsColumns).forEach((col, i) => {
    if (clientColumns[i].name === col.name) {
      clientColumns[i].hidden = !col.show
    }
  })
}

export default {
  columnModal: false,
  columns: columns,
  clientColumns: clientColumns
}
