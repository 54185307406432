import tasks from './tasks'
import trades from './trades'
import company from './company'
import contracts from './contracts'
import analytics from './analytics'
import cabinet from './cabinet'
import osContracts from './osContracts'
import directory from './directory'
import widgets from './widgets'
import memos from './memos'
import edo from './edo'
import managerDesktopTrades from './managerDesktopTrades'

export default {
  tasks: tasks,
  trades: trades,
  companies: company,
  contracts: contracts,
  analytics: analytics,
  cabinet: cabinet,
  osContracts: osContracts,
  directories: directory,
  widgets: widgets,
  memos: memos,
  edo: edo,
  managerDesktopTrades: managerDesktopTrades
}
