let columns = [
  {
    label: 'Дата',
    field: 'date',
    hidden: false
  },
  {
    label: 'Сделка',
    field: 'trade',
    hidden: false
  },
  {
    label: '№ проекта',
    field: 'projectNumber',
    hidden: false
  },
  {
    label: 'Договор',
    field: 'contract',
    hidden: false
  },
  {
    label: 'Стадия',
    field: 'stage',
    hidden: false
  },
  {
    label: 'Клиент',
    field: 'company',
    hidden: false
  },
  {
    label: 'Статус',
    field: 'status',
    hidden: false
  },
  {
    label: 'Имущество',
    field: 'calc',
    hidden: false
  },
  {
    label: 'Стоимость',
    field: 'price',
    hidden: false
  },
  {
    label: 'След. контакт',
    field: 'nextTaskDate',
    hidden: false
  },
  {
    label: 'Комментарий',
    field: 'serviceComment',
    hidden: false
  },
  {
    label: 'Ответственный',
    field: 'responsible_user',
    hidden: true
  },
  {
    label: 'Подразделение',
    field: 'group',
    hidden: true
  },
  {
    label: 'Создатель',
    field: 'creator',
    hidden: true
  }
]
if (localStorage.managerDesktopTradeColumns) {
  JSON.parse(localStorage.managerDesktopTradeColumns).forEach((col, i) => {
    if (columns[i].name === col.name) {
      columns[i].hidden = !col.show
    }
  })
}

export default {
  checked: false,
  page: 0,
  perPage: 50,
  isLoading: true,
  showFilter: true,
  sort: {
    field: 'createdAt',
    order: 'desc'
  },
  filter: {
    st: null,
    status: 'atWork',
    responsible_user_id: null,
    created_by: null,
    group_id: [],
    createdAt: null,
    updatedAt: null,
    tags: [],
    callCenter: false,
    createdBy: false
  },
  view: 'table',
  sort2: 'month',
  sortDate: null,
  columnModal: false,
  columns: columns,
  mainDataModal: false,
  contactDataModal: false,
  checkTradeModal: false,
  loadCheckTradeData: false,
  checkGuarantorModal: false,
  guarantorData: null,
  contactDataModalProps: {
    companyId: null,
    data: null,
    type: null
  },
  companyDataModal: false,
  companyDataModalProps: {
    elementId: null,
    data: null,
    elementType: null,
    isCanceled: null
  },
  checkSuccessModal: false,
  checkTradeClose: false,
  calcModal: false,
  calcDataModalProps: {
    leadId: null,
    type: null,
    header: null,
    data: null
  },
  fullCalcModal: false,
  fullCalcDataModalProps: {
    leadId: null,
    type: null,
    header: null,
    data: null
  },
  docsModal: false,
  status: null
}
