let columns = [
  { label: '', field: 'sign', sortable: false, hidden: false },
  // { label: 'Отклонить', field: 'reject', hidden: false },
  { label: 'Ответственный', field: 'responsible', hidden: false },
  { label: 'Подписант', field: 'signer', hidden: false },
  { label: 'Получатель', field: 'toTitle', hidden: false },
  { label: 'Договор', field: 'contract', hidden: false },
  { label: 'Оператор', field: 'operator', hidden: false },
  { label: 'Файлы', field: 'files', hidden: false },
  { label: 'Вид', field: 'view' },
  { label: 'Автор', field: 'fromTitle', hidden: false },
  { label: 'Дата', field: 'date', hidden: false }
]

if (localStorage.edoColumns) {
  JSON.parse(localStorage.edoColumns).forEach((col, i) => {
    if (columns[i].name === col.name) {
      columns[i].hidden = !col.show
    }
  })
}

let signedColumns = [
  { label: 'Дата', field: 'date', hidden: false },
  { label: 'Наименование', field: 'companyName', hidden: false },
  { label: 'Статус', field: 'status', hidden: false },
  { label: 'Файлы', field: 'attachments', hidden: false },
  { label: 'Оператор', field: 'operator', hidden: false }
]

if (localStorage.edoSignedColumns) {
  JSON.parse(localStorage.edoSignedColumns).forEach((col, i) => {
    if (columns[i].name === col.name) {
      columns[i].hidden = !col.show
    }
  })
}

export default {
  columnModal: false,
  signedColumnModal: false,
  columns: columns,
  signedColumns: signedColumns,
  modal: {
    show: false,
    recipient: null,
    guarantor: null,
    recipientList: [],
    selectedOperator: null,
    selectedRecipient: null,
    defaultSigner: null,
    send: false,
    edoData: null,
    edoDataArray: []
  }
}
