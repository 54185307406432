let columns = [
  {
    label: 'Наименование',
    field: 'name',
    hidden: false
  },
  {
    label: 'Дата',
    field: 'createdAt',
    hidden: false
  },
  {
    label: 'Статус',
    field: 'status',
    hidden: false
  },
  {
    label: 'Компания',
    field: 'partner',
    hidden: false
  },
  {
    label: 'Следующий платеж',
    field: 'nextPayment',
    hidden: false
  },
  {
    label: 'Задолженность, ₽',
    field: 'debt',
    hidden: false
  },
  {
    label: 'Остаток платежей, ₽',
    field: 'payments',
    hidden: false
  },
  {
    label: 'Остаток срока',
    field: 'duration',
    hidden: false
  },
  {
    label: 'Сумма, ₽',
    field: 'summ',
    hidden: false
  },
  {
    label: 'Менеджер',
    field: 'manager',
    hidden: false
  }
]

let columnsClient = [
  {
    label: 'Наименование',
    field: 'name',
    hidden: false
  },
  {
    label: 'Дата',
    field: 'createdAt',
    hidden: false
  },
  {
    label: 'Компания',
    field: 'partner',
    hidden: false
  },
  {
    label: 'Статус',
    field: 'status',
    hidden: false
  },
  {
    label: 'Следующий платеж',
    field: 'nextPayment',
    hidden: false
  },
  {
    label: 'Задолженность, ₽',
    field: 'debt',
    hidden: false
  },
  {
    label: 'Остаток платежей, ₽',
    field: 'payments',
    hidden: false
  },
  {
    label: 'Остаток срока',
    field: 'duration',
    hidden: false
  },
  {
    label: 'Сумма, ₽',
    field: 'summ',
    hidden: false
  }
]

let filters = [
  { label: 'Статус', field: 'status', hidden: false },
  // { label: 'Компания', field: 'company', hidden: false },
  { label: 'Теги', field: 'tags', hidden: false },
  { label: 'Период', field: 'period', hidden: false }
]

if (localStorage.contractsColumns) {
  JSON.parse(localStorage.contractsColumns).forEach((col, i) => {
    if (columns[i].name === col.name) {
      columns[i].hidden = !col.show
    }
  })
}

if (localStorage.contractsFilters) {
  JSON.parse(localStorage.contractsFilters).forEach((filter, i) => {
    if (columns[i].name === filter.name) {
      columns[i].hidden = !filter.show
    }
  })
}

// if (localStorage.contractsClientColumns) {
//   JSON.parse(localStorage.contractsClientColumns).forEach((col, i) => {
//     if (columnsClient[i].name === col.name) {
//       columnsClient[i].hidden = !col.show
//     }
//   })
// }

export default {
  page: 0,
  perPage: 50,
  period: [
    new Date(2019, 0, 1),
    new Date(Date.now())
  ],
  sort: {
    field: 'date',
    order: 'desc'
  },
  filter: {
    q: '',
    st: null,
    status: null,
    status_date: null,
    tags: [],
    partner: {
      id: null,
      name: null,
      inn: null
    },
    signed: null,
    unloaded: null,
    advance: null
  },
  view: 'table',
  sort2: null,
  sortDate: null,
  columnModal: false,
  columns: columns,
  filters: filters,
  columnsClient: columnsClient
}
