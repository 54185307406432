import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'

const Home = () => import('../views/Home.vue')
const Auth = () => import('../views/Auth.vue')
const TV = () => import('../views/TV.vue')
const Registration = () => import('../views/Registration.vue')
const SmsRegistration = () => import('../views/SmsRegistration.vue')
const Activation = () => import('../views/Activation.vue')
const ActivationAccount = () => import('../views/ActivationAccount.vue')
const Resend = () => import('../views/Resend.vue')
const Projects = () => import('../views/projects/Projects.vue')
const Contracts = () => import('../views/contracts/Contracts.vue')
const Contract = () => import('../views/contracts/Contract.vue')
// const Partners = () => import('../views/partners/Partners.vue'
const Faq = () => import('../views/faq/Faq.vue')
const Question = () => import('../views/question/Question.vue')
const Support = () => import('../views/support/Support.vue')
const Appeals = () => import('../views/appeals/Appeals.vue')
const Cabinet = () => import('../views/cabinet/Cabinet.vue')
const Trades = () => import('../views/trade/Trades.vue')
const Trade = () => import('../views/trade/Trade.vue')
const Calculator = () => import('../views/calculator/Calculator.vue')
const CalculatorNew = () => import('../views/calculator/CalculatorNew.vue')
const Contacts = () => import('../views/contacts/Contacts.vue')
const Contact = () => import('../views/contacts/Contact.vue')
const AddContact = () => import('../views/contacts/Add.vue')
const Companies = () => import('../views/companies/Companies.vue')
const Company = () => import('../views/companies/Company.vue')
const AddCompany = () => import('../views/companies/Add.vue')
const AddBase = () => import('../views/companies/AddBase.vue')
const Sign = () => import('../views/companies/Sign.vue')
// const CompaniesContacts = () => import('../views/companies_contacts/Index.vue'
const Tasks = () => import('../views/tasks/Tasks.vue')
// const CheckTrade = () => import('../views/trade/check/index.vue'
const Analytics = () => import('../views/Analytics.vue')
const Diagrams = () => import('../views/Diagrams.vue')
const Candidates = () => import('../views/candidates/Candidates.vue')
const Candidate = () => import('../views/candidates/Candidate.vue')
const AddCandidate = () => import('../views/candidates/Add.vue')
const Apply = () => import('../views/apply/Apply.vue')
const Admin = () => import('../views/admin/Admin.vue')
const Directory = () => import('../views/directory/Directory.vue')
const Events = () => import('../views/events/Events.vue')
const Memos = () => import('../views/memo/MemosNew.vue')
const AddMemo = () => import('../views/memo/AddMemo.vue')
const Courses = () => import('../views/courses/Courses.vue')
const Calculators = () => import('../views/calculators/Calculators.vue')
const Edo = () => import('../views/edo/Edo.vue')
const CheckTrade = () => import('../views/trade/check/CheckTrade.vue')
const PropertyValuation = () => import('../views/PropertyValuation.vue')
const Insurance = () => import('../views/Insurance.vue')
const SeizedProperties = () => import('../views/seized_properties/SeizedProperties.vue')
const SeizedPropertiesSelected = () => import('../views/seized_properties/SeizedPropertiesSelected.vue')
// const Documentation = () => import('../views/documentation/Documentation.vue'
// const Calculator = () => import('../views/calculator/Calculator.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      subscribe: true,
      title: 'Рабочий стол'
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: {
      subscribe: false
    }
  },
  {
    path: '/registration/sms',
    name: 'smsRegistration',
    component: SmsRegistration,
    meta: {
      subscribe: false
    }
  },
  {
    path: '/registration',
    name: 'registration',
    component: Registration,
    meta: {
      subscribe: false
    }
  },
  {
    path: '/lostpass',
    name: 'lostpass',
    component: Resend,
    meta: {
      subscribe: false
    }
  },
  {
    path: '/activation/account',
    name: 'activationAccount',
    component: ActivationAccount,
    meta: {
      subscribe: false
    }
  },
  {
    path: '/activation',
    name: 'activation',
    component: Activation,
    meta: {
      subscribe: false
    }
  },
  {
    path: '/tv',
    name: 'tv',
    component: TV,
    meta: {
      subscribe: true,
      isEmployee: true
    }
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    meta: {
      subscribe: true,
      title: 'Лизинговые проекты'
    }
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: Contracts,
    meta: {
      subscribe: true,
      title: 'Договоры'
    }
  },
  {
    path: '/contracts/:id',
    name: 'contract',
    meta: {
      parent: 'contracts',
      subscribe: true
    },
    props: true,
    component: Contract
  },
  {
    path: '/apply',
    name: 'apply',
    component: Apply,
    meta: {
      subscribe: true
    }
  },
  // {
  //   path: '/partners',
  //   name: 'partners',
  //   component: Partners,
  //   meta: {
  //     subscribe: true,
  //     title: 'Партнеры'
  //   }
  // },
  {
    path: '/analytics',
    name: 'analytics',
    component: Analytics,
    meta: {
      subscribe: true,
      title: 'Аналитика'
    }
  },
  {
    path: '/diagrams',
    name: 'diagrams',
    component: Diagrams,
    meta: {
      subscribe: true,
      title: 'Диаграммы',
      isAdmin: true
    }
  },
  {
    path: '/edo',
    name: 'edo',
    component: Edo,
    meta: {
      subscribe: true,
      title: 'ЭДО'
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: {
      subscribe: true,
      title: 'Вопросы и ответы'
    }
  },
  {
    path: '/question',
    name: 'question',
    component: Question,
    meta: {
      subscribe: true
    }
  },
  {
    path: '/support',
    name: 'support',
    component: Support,
    meta: {
      subscribe: true,
      title: 'Поддержка'
    }
  },
  {
    path: '/appeals',
    name: 'appeals',
    component: Appeals,
    meta: {
      subscribe: true
    }
  },
  {
    path: '/cabinet',
    name: 'cabinet',
    component: Cabinet,
    meta: {
      subscribe: true,
      title: 'Личный кабинет'
    }
  },
  {
    path: '/courses',
    name: 'courses',
    component: Courses,
    meta: {
      subscribe: true,
      title: 'Курсы'
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      subscribe: true
    }
  },
  {
    path: '/trade/:id',
    name: 'trade',
    meta: {
      parent: 'trades',
      subscribe: true
    },
    props: true,
    component: Trade
  },
  {
    path: '/trade/:id/new',
    name: 'trade_new',
    meta: {
      parent: 'trades',
      subscribe: true
    },
    props: true,
    component: Trade
  },
  {
    path: '/trade/:id/check',
    name: 'check_trade',
    meta: {
      parent: 'trades',
      subscribe: true
    },
    props: true,
    component: CheckTrade
  },
  {
    path: '/trade/add',
    name: 'add-trade',
    meta: {
      parent: 'trades',
      subscribe: true,
      title: 'Добавление сделки'
    },
    props: true
  },
  // {
  //   path: '/trades/check/:id/:step?',
  //   name: 'check-trade',
  //   meta: {
  //     parent: 'trades',
  //     subscribe: true
  //   },
  //   props: true,
  //   component: CheckTrade
  // },
  {
    path: '/trades',
    name: 'trades',
    component: Trades,
    meta: {
      subscribe: true,
      title: 'Сделки'
    }
  },
  {
    path: '/calculators',
    name: 'calculators',
    component: Calculators,
    meta: {
      subscribe: true,
      title: 'Калькуляторы'
    }
  },
  {
    path: '/memos',
    name: 'memos',
    component: Memos,
    meta: {
      subscribe: true,
      title: 'Служебки'
    }
  },
  {
    path: '/addmemo',
    name: 'add_memo',
    meta: {
      parent: 'memos',
      subscribe: true,
      title: 'Создать служебку'
    },
    component: AddMemo
  },
  {
    path: '/calculus',
    name: 'calculus',
    component: CalculatorNew,
    meta: {
      subscribe: true,
      title: 'Калькулятор'
    }
  },
  {
    path: '/calculus/new',
    name: 'calculus_new',
    component: Calculator,
    meta: {
      subscribe: true,
      title: 'Расчётный калькулятор'
    }
  },
  {
    path: '/candidates',
    name: 'candidates',
    component: Candidates,
    meta: {
      subscribe: true,
      title: 'Кандидаты'
    }
  },
  {
    path: '/candidates/:id',
    name: 'candidate',
    meta: {
      parent: 'candidates',
      subscribe: true
    },
    props: true,
    component: Candidate
  },
  {
    path: '/addcandidate',
    name: 'add_candidate',
    meta: {
      parent: 'candidates',
      subscribe: true,
      title: 'Добавление кандидата'
    },
    component: AddCandidate
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
    meta: {
      subscribe: true,
      title: 'Контакты'
    }
  },
  {
    path: '/contacts/:id',
    name: 'contact',
    meta: {
      parent: 'contacts',
      subscribe: true
    },
    props: true,
    component: Contact
  },
  {
    path: '/addcontact',
    name: 'add_contact',
    meta: {
      parent: 'contacts',
      subscribe: true,
      title: 'Добавление контакта'
    },
    component: AddContact
  },
  {
    path: '/companies',
    name: 'companies',
    component: Companies,
    meta: {
      parent: 'trades',
      subscribe: true,
      title: 'Компании'
    }
  },
  {
    path: '/companies/:id',
    name: 'company',
    meta: {
      parent: 'companies',
      subscribe: true
    },
    props: true,
    component: Company
  },
  {
    path: '/addcompany',
    name: 'add_company',
    meta: {
      parent: 'companies',
      subscribe: true,
      title: 'Добавление компании'
    },
    component: AddCompany
  },
  {
    path: '/addbase',
    name: 'add_base',
    meta: {
      parent: 'companies',
      subscribe: true,
      title: 'Загрузка базы',
      isAdmin: true
    },
    component: AddBase
  },
  {
    path: '/sign/:inn',
    name: 'signNew',
    meta: {
      parent: 'sign',
      subscribe: true,
      title: 'Онлайн подписание'
    },
    props: true,
    component: Sign
  },
  {
    path: '/sign',
    name: 'sign',
    meta: {
      parent: 'companies',
      subscribe: true,
      title: 'Онлайн подписание'
    },
    component: Sign
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: Tasks,
    meta: {
      subscribe: true,
      title: 'Задачи'
    }
  },
  {
    path: '/directory',
    name: 'directory',
    component: Directory,
    meta: {
      subscribe: true,
      title: 'Справочник'
    }
  },
  {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
      subscribe: true,
      title: 'Новости'
    }
  },
  {
    path: '/property-valuation',
    name: 'property-valuation',
    component: PropertyValuation,
    meta: {
      subscribe: true,
      title: 'Оценка авто'
    }
  },
  {
    path: '/insurance',
    name: 'insurance',
    component: Insurance,
    meta: {
      subscribe: true,
      title: 'Страхование КАСКО'
    }
  },
  {
    path: '/seized-properties/:hash',
    name: 'seized-properties-selected',
    component: SeizedPropertiesSelected,
    meta: {
      parent: 'seized-properties',
      subscribe: true,
      title: 'Изъятое имущество'
    },
    props: true
  },
  {
    path: '/seized-properties',
    name: 'seized-properties',
    component: SeizedProperties,
    meta: {
      subscribe: true,
      title: 'Изъятое имущество'
    }
  },
  // {
  //   path: '/companies_contacts',
  //   name: 'companies_contacts',
  //   component: CompaniesContacts,
  //   meta: {
  //     subscribe: true,
  //     title: 'Компании и контакты'
  //   }
  // },
  {
    path: '*',
    component: Home,
    meta: {
      subscribe: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  saveScrollPosition: true,
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  next()
})
router.afterEach((to, from) => {
  NProgress.done()
})

export default router
