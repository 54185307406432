import moment from 'moment'
import { extendMoment } from 'moment-range'

moment.updateLocale('ru', {
  week: {
    dow: 1
  }
})

const _moment = extendMoment(moment)

const Today = _moment()
const Tomorrow = _moment().add(1, 'days')
const Week = _moment().endOf('week')
const NextWeek = _moment().endOf('week').add(1, 'week')

function getStatus (date) {
  const target = _moment(date)
  if (_moment().range(_moment(Today).startOf('day'), _moment(Today).endOf('day')).contains(target)) {
    return 'На сегодня'
  } else if (_moment().range(_moment(Tomorrow).startOf('day'), _moment(Tomorrow).endOf('day')).contains(target)) {
    return 'На завтра'
  } else if (_moment().range(_moment(Today).startOf('day'), _moment(Week).endOf('week')).contains(target)) {
    return 'Эта неделя'
  } else if (_moment().range(_moment(NextWeek).startOf('week'), _moment(NextWeek).endOf('week')).contains(target)) {
    return 'След. неделя'
  } else if (_moment(NextWeek).endOf('week').format('x') < target.format('x')) {
    return 'После след. недели'
  } else {
    return 'Просроченные'
  }
}

export default {
  setHoldingData (state, value) {
    state.data.companies.holdingDataModal = true
    state.data.companies.holdingDataModalProps.elementId = value
  },
  clearHoldingData (state, value) {
    state.data.companies.holdingDataModal = false
    state.data.companies.holdingDataModalProps.elementId = null
    state.data.companies.holdingDataModalProps.type = null
  },
  allDoTasksMeta (state, data) {
    state._allDoTasksMeta = data._allDoTasksMeta
  },
  diadocDocuments (state, data) {
    state.diadocDocuments = data.sbisDocuments?.totalCount + data.diadocDocuments?.totalCount
  },
  allFaqResponsible (state, data) {
    state.allFaqResponsible = data.allFaqResponsible
  },
  allThemes (state, data) {
    state.allThemes = data.allThemes
  },
  providersList (state, data) {
    state.providerList = data.providerList
  },
  agentsList (state, data) {
    state.agentList = data.agentList
  },
  me (state, data) {
    state.me = data.me
    state.data.tasks.filter.responsible_user_id = data.me._id
    if (data.me.tours && data.me.tours.length) {
      state.me.tours.sort((a, b) => a.order - b.order)
    } else {
      state.me.tours = []
    }
  },
  lastEvents (state, data) {
    state.lastEvents = data.lastEvents
  },
  // allMemos (state, data) {
  //   state.allMemos, data.allMemos
  // },
  allGroups (state, data) {
    state.allGroups = data.allGroups
  },
  allTags (state, data) {
    state.allTags = data.allTags
  },
  allHoldings (state, data) {
    state.allHoldings = data.allHoldings
  },
  allUsers (state, data) {
    state.allUsers = data.allUsers
  },
  allContracts (state, data) {
    state.allContracts = data.allContracts
  },
  allTours (state, data) {
    state.allTours = data.allTours
    if (data.allTours && data.allTours.length) {
      state.allTours.sort((a, b) => a.order - b.order)
    } else {
      state.allTours = []
    }
  },
  incrementTradeRerenderKey (state, data) {
    state.data.trades.rerenderKey++
  },
  incrementCalcRerenderKey (state, data) {
    state.data.trades.rerenderCalcKey++
  },
  incrementCompanyRerenderKey (state, data) {
    state.data.companies.rerenderKey++
  },
  checkProject (state, data) {
    state.checkProject = data.checkProject
  },
  lead (state, data) {
    state.Lead = data.Lead
  },
  guarantor (state, data) {
    state.checkProject = data.checkCompany
  },
  leadStatusList (state, data) {
    state.leadStatusListClear = data.leadStatusList
    state.leadStatusList = data.leadStatusList.map(item => item.title)
    state.leadStatusList.unshift('Все')
  },
  setAuthToken (state, token) {
    state.authToken = token
    localStorage.setItem('apollo-token', token)
  },

  removeAuthToken (state) {
    state.authToken = null
    localStorage.removeItem('apollo-token')
  },

  setSideBarState (state, value) {
    state.sideBarState = value
    localStorage.setItem('sidebar-state', value)
  },

  showOverlay (state, { show, text }) {
    state.overlayShow = show
    state.overlayText = text
  },

  hideOverlay (state) {
    state.overlayShow = false
    state.overlayText = ''
  },
  switchShowAddTradeModal (state) {
    state.showAddTradeModal = !state.showAddTradeModal
  },
  switchShowCloseTradeModal (state) {
    state.showCloseTradeModal = !state.showCloseTradeModal
  },
  switchShowDeleteTradeModal (state) {
    state.showDeleteTradeModal = !state.showDeleteTradeModal
  },
  switchShowDeleteTaskModal (state) {
    state.showDeleteTaskModal = !state.showDeleteTaskModal
  },
  expiredTasks (state, data) {
    state.expiredTasks = data._allTasksMeta.count
  },
  unprocessedLeads (state, data) {
    state.unprocessedLeads = data._unprocessedLeadsMeta.count
  },
  unprocessesSupports (state, data) {
    state.unprocessesSupports = data._supportMeta.count
  },
  unprocessesAppeals (state, data) {
    state.unprocessesAppeals = data._appealMeta.count
  },
  allCompanies (state, { data, isMore }) {
    state.allCompanies = data.allCompanies
  },
  allDirectories (state, { data, isMore }) {
    state.allDirectories = data ? data.allDirectories : []
    state.allDepartments = data ? data.allDepartments : []
    state.allSubdivisions = data ? data.allSubdivisions : []
  },
  allContacts (state, { data, isMore }) {
    state.allContacts = data.allContacts
  },
  allTasks (state, { data, isMore }) {
    if (isMore) {
      state.allTasks = state.allTasks.concat(data.allTasks.map((item, i) => {
        item.id = item._id
        item.status = item.is_completed ? 'Завершенные' : getStatus(item.complete_till_at)
        return item
      }))
    } else {
      state.allTasks = data.allTasks.map((item, i) => {
        item.id = item._id
        item.status = item.is_completed ? 'Завершенные' : getStatus(item.complete_till_at)
        return item
      })
    }
  },
  allLeadsKanban (state, data) {
    if (data.isMore) {
      state.allLeadsKanban = state.allLeadsKanban.concat(data.leads.map((item, i) => {
        item.count = data.meta.find(m => m.category === item.status).count
        if (state.leadStatusList) {
          for (let i = 0; i < state.leadStatusListClear.length; i++) {
            if (state.leadStatusListClear[i].value === item.status) {
              item.status = state.leadStatusListClear[i].title
              break
            }
          }
        }
        item.id = item._id
        item.date = item.createdAt
        item.type = 'lead'
        return item
      }))
    } else {
      state.allLeadsKanban = data.leads.map((item, i) => {
        item.count = data.meta.find(m => m.category === item.status).count
        if (state.leadStatusList) {
          for (let i = 0; i < state.leadStatusListClear.length; i++) {
            if (state.leadStatusListClear[i].value === item.status) {
              item.status = state.leadStatusListClear[i].title
              break
            }
          }
        }
        item.id = item._id
        item.date = item.createdAt
        item.type = 'lead'
        return item
      })
    }
  },
  allLeads (state, { data, isMore }) {
    state._allLeadsMeta = data._allLeadsMeta.count
    if (isMore) {
      state.allLeads = state.allLeads.concat(data.allLeads.map((item, i) => {
        if (state.leadStatusList) {
          for (let i = 0; i < state.leadStatusListClear.length; i++) {
            if (state.leadStatusListClear[i].value === item.status) {
              item.status = state.leadStatusListClear[i].title
              break
            }
          }
        }
        item.id = item._id
        item.date = item.createdAt
        item.type = 'lead'
        return item
      }))
    } else {
      state.allLeads = data.allLeads.map((item, i) => {
        if (state.leadStatusList) {
          for (let i = 0; i < state.leadStatusListClear.length; i++) {
            if (state.leadStatusListClear[i].value === item.status) {
              item.status = state.leadStatusListClear[i].title
              break
            }
          }
        }
        item.id = item._id
        item.date = item.createdAt
        item.type = 'lead'
        return item
      })
    }
  },
  allProjects (state, { data, isMore }) {
    state._allProjectsMeta = data._allProjectsMeta
    if (isMore) {
      state.allProjects = state.allProjects.concat(data.allProjects.map((item, i) => {
        if (state.leadStatusList) {
          for (let i = 0; i < state.leadStatusListClear.length; i++) {
            if (state.leadStatusListClear[i].value === item.status) {
              item.status = state.leadStatusListClear[i].title
              break
            }
          }
        }
        item._id = item.id
        item.type = 'project'
        return item
      }))
    } else {
      state.allProjects = data.allProjects.map((item, i) => {
        if (state.leadStatusList) {
          for (let i = 0; i < state.leadStatusListClear.length; i++) {
            if (state.leadStatusListClear[i].value === item.status) {
              item.status = state.leadStatusListClear[i].title
              break
            }
          }
        }
        item._id = item.id
        item.type = 'project'
        return item
      })
    }
  },
  theRealMess (state, sort) {
    state.trades = state.allProjects
    state.trades = state.trades.concat(state.allLeads)
    if (sort.field === 'date') {
      state.trades = state.trades.sort((a, b) => new Date(b.date) - new Date(a.date))
    } else {
      state.trades = state.trades.sort()
    }
    if (sort.order !== 'desc') {
      state.trades = state.trades.reverse()
    }
  }
}
