let columns = [
  {
    label: 'Контрагент',
    field: 'partner',
    hidden: false
  },
  {
    label: 'Договор лизинга',
    field: 'name',
    hidden: false
  },
  {
    label: 'Имущество',
    field: 'property',
    hidden: false
  },
  {
    label: 'Дата',
    field: 'createdAt',
    hidden: false
  },
  {
    label: 'Статус',
    field: 'status',
    hidden: false
  },
  {
    label: 'Задолженность Итого, ₽',
    field: 'debt',
    hidden: false
  },
  {
    label: 'Задолженность тек.мес., ₽',
    field: 'dz1',
    hidden: false
  },
  {
    label: 'Задолженность 2-й мес., ₽',
    field: 'dz2',
    hidden: false
  },
  {
    label: 'Задолженность от 3 мес. и более, ₽',
    field: 'dz3',
    hidden: false
  },
  {
    label: 'Остаток лизинговых платежей, ₽',
    field: 'payments',
    hidden: false
  },
  {
    label: 'Срок окончания лизинга',
    field: 'duration',
    hidden: false
  },
  {
    label: 'Позвонить',
    field: 'phone',
    hidden: false
  },
  {
    label: 'Комментарий',
    field: 'comment',
    width: '150px',
    hidden: false
  }
]

if (localStorage.osContractsColumns) {
  JSON.parse(localStorage.osContractsColumns).forEach((col, i) => {
    if (columns[i].name === col.name) {
      columns[i].hidden = !col.show
    }
  })
}

export default {
  page: 0,
  perPage: 10,
  period: [
    new Date(2019, 0, 1),
    new Date(Date.now())
  ],
  sort: {
    field: 'date',
    order: 'desc'
  },
  filter: {
    q: '',
    st: null,
    status: null,
    status_date: null,
    tags: [],
    partner: {
      id: null,
      name: null,
      inn: null
    },
    signed: null,
    unloaded: null,
    advance: null
  },
  view: 'table',
  sort2: null,
  sortDate: null,
  columnModal: false,
  columns: columns
}
