export default {
  mainDataModal: false,
  contactDataModal: false,
  contactDataModalProps: {
    companyId: null,
    data: null,
    type: null
  },
  companyDataModal: false,
  companyDataModalProps: {
    elementId: null,
    data: null,
    elementType: null,
    isCanceled: null
  },
  checkSuccessModal: false
}
