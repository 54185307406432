let columns = [
  { label: 'Наименование', field: 'name', hidden: false },
  { label: 'Тип', field: 'types', hidden: false },
  { label: 'Дата', field: 'createdAt', hidden: false },
  { label: 'ИНН', field: 'inn', hidden: false },
  { label: 'Email', field: 'email', hidden: false },
  { label: 'Телефон', field: 'phone', hidden: false },
  { label: 'Подразделение', field: 'group_id', hidden: false },
  { label: 'Ответственный', field: 'responsible_user_id', hidden: false },
  { label: 'Лимит сумма', field: 'limit_summ', hidden: false },
  { label: 'Лимит количество', field: 'limit_count', hidden: false },
  { label: 'Задачи', field: 'tasks', hidden: false },
  { label: 'Комментарий', field: 'lastNote', hidden: true }
]

let filters = [
  { label: 'Параметры 1С', field: 'params', hidden: false },
  { label: 'Теги', field: 'tags', hidden: false },
  { label: 'Тип', field: 'types', hidden: false },
  { label: 'Ответственный', field: 'responsible', hidden: false },
  { label: 'Подразделение', field: 'group', hidden: false },
  { label: 'ИНН', field: 'inn', hidden: false },
  { label: 'Поиск', field: 'q', hidden: false },
  { label: 'Холдинг', field: 'holding', hidden: false },
  { label: 'Рейтинг', field: 'rating', hidden: false },
  { label: 'Нет задач', field: 'notTasks', hidden: false },
  { label: 'Есть лимит', field: 'limit', hidden: false },
  { label: 'Есть согласие', field: 'sign', hidden: false }
]

if (localStorage.companyColumns) {
  JSON.parse(localStorage.companyColumns).forEach((col, i) => {
    if (columns[i].name === col.name) {
      columns[i].hidden = !col.show
    }
  })
}

if (localStorage.companyFilters) {
  JSON.parse(localStorage.companyFilters).forEach((filter, i) => {
    if (filters[i].name === filter.name) {
      filters[i].hidden = !filter.show
    }
  })
}

export default {
  page: 0,
  perPage: 50,
  showFilter: true,
  sort: {
    field: 'createdAt',
    order: 'desc'
  },
  allCompaniesMeta: 0,
  filter: {
    name: '',
    email: '',
    phone: '',
    inn: '',
    tags: [],
    responsible_user_id: null,
    group_id: [],
    atags: [],
    activeTasks: null,
    hasContracts: null,
    limit: null,
    signed: null,
    holding: null,
    rating: null,
    types: []
  },
  typeOptions: [
    'Лизингополучатель',
    'Поставщик',
    'Поручитель',
    'Агент'
  ],
  inn: null,
  otherLeasingModal: false,
  columnModal: false,
  columns: columns,
  filters: filters,
  holdingDataModal: false,
  holdingDataModalProps: {
    elementId: null,
    elementName: ''
  },
  rerenderKey: 0
}
