import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { createProvider } from './vue-apollo'
import Notifications from 'vue-notification'
import CheckboxRadio from 'vue-checkbox-radio'
import VueMq from 'vue-mq'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import Tabs from 'vue-tabs-component'
import VTooltip from 'v-tooltip'
import VueKanban from 'vue-kanban'
import vSelect from 'vue-select'
import ScrollLoader from 'vue-scroll-loader'
import vClickOutside from 'v-click-outside'
import Paginate from 'vuejs-paginate'
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import VueTour from 'vue-tour'
import draggable from 'vuedraggable'

require('moment/locale/ru')

Vue.config.productionTip = false

Vue.use(vClickOutside)
Vue.use(ScrollLoader)
Vue.use(Notifications)
Vue.use(CheckboxRadio)
Vue.use(VueMq, {
  breakpoints: {
    xs: -Infinity,
    sm: 480,
    xsm: 540,
    md: 768,
    lg: 960,
    xlg: 980,
    xl: Infinity
  },
  defaultBreakpoint: 'sm'
})
Vue.use(VueMoment, {
  moment
})
Vue.use(Tabs)
Vue.use(VueKanban)
Vue.use(VTooltip)
Vue.use(VueGoodTablePlugin)
Vue.use(VueTour)
Vue.component('v-select', vSelect)
Vue.component('paginate', Paginate)
Vue.component('draggable', draggable)

router.beforeEach((to, from, next) => {
  if (
    store.state.authToken ||
    to.path === '/auth' ||
    to.path === '/registration' ||
    to.path === '/activation' ||
    to.path === '/lostpass' ||
    to.path === '/registration/sms' ||
    to.path === '/activation/account'
  ) {
    if (store.state.authToken && to.meta.isAdmin && store.state.me) {
      if (['headmanager', 'callcenter', 'admin'].includes(store.state.me.role)) {
        next()
      } else {
        next('/')
      }
    } else {
      next()
    }
  } else {
    next('/auth')
  }
})

new Vue({
  store,
  router,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
