import ME from '../graphql/queries/Me.gql'
import ALL_TASKS from '../graphql/queries/AllTasks.gql'
import ALL_GROUPS from '../graphql/queries/AllGroups.gql'
import EXPIRED_TASKS from '../graphql/queries/ExpiredTasks.gql'
import ALL_LEADS from '../graphql/queries/AllLeads.gql'
import ALL_MEMOS from '../graphql/queries/allMemos.gql'
import ALL_TAGS from '../graphql/queries/AllTags.gql'
import ALL_USERS from '../graphql/queries/AllUsers.gql'
import ALL_HOLDINGS from '../graphql/queries/AllHoldings.gql'
import ALL_PROJECTS from '../graphql/queries/AllProjects.gql'
import UPDATE_LEAD from '../graphql/mutations/UpdateLead.gql'
import UPDATE_TASK from '../graphql/mutations/UpdateTask.gql'
import UPDATE_PROJECT from '../graphql/mutations/UpdateProject.gql'
import CHECK_PROJECT from '../graphql/queries/CheckProject.gql'
import LEAD from '../graphql/queries/Lead.gql'
import LEAD_STATUS_LIST from '../graphql/queries/leadStatusList.gql'
import AGENTS_LIST from '@/graphql/queries/AgentsList.gql'
import PROVIDERS_LIST from '@/graphql/queries/ProvidersList.gql'
import GraphQLHelper from '../helpers/GraphQLHelper'
import ALL_THEMES from '@/graphql/queries/AllThemes.gql'
import RESPONSIBLE from '@/graphql/queries/AllFaqResponsible.gql'
import UNPROCESSES_APPEALS from '@/graphql/queries/UnprocessesAppeals.gql'
import UNPROCESSES_SUPPORTS from '@/graphql/queries/UnprocessesSupports.gql'
import TOURS from '@/graphql/queries/AllTours.gql'
import LAST_EVENTS from '@/graphql/queries/LastEvents.gql'
import UNPROCESSED_LEADS from '@/graphql/queries/UnprocessedLeads.gql'
import DO_META from '@/graphql/queries/AllDoTasksMeta.gql'
import GUARANTOR from '../graphql/queries/Guarantor.gql'
import DIADOC_DOCUMENTS from '../graphql/queries/DiadocDocuments.gql'

const _graphQlHelper = new GraphQLHelper()

export default {
  async diadocDocuments ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: DIADOC_DOCUMENTS,
      update: (store, { data }) => {
        if (data && data.diadocDocuments && data.sbisDocuments) {
          commit('diadocDocuments', data)
        }
      }
    })
  },
  async allDoTasksMeta ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: DO_META,
      update: (store, { data }) => {
        if (data && data._allDoTasksMeta && data._allDoTasksMeta.count) {
          commit('allDoTasksMeta', data)
        }
      }
    })
  },
  async allFaqResponsible ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: RESPONSIBLE,
      update: (store, { data }) => {
        commit('allFaqResponsible', data)
      }
    })
  },
  async allThemes ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: ALL_THEMES,
      update: (store, { data }) => {
        commit('allThemes', data)
      }
    })
  },
  async agentsList ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: AGENTS_LIST,
      update: (store, { data }) => {
        commit('agentsList', data)
      }
    })
  },
  async providersList ({ commit }, { context, data }) {
    await context.$apollo.mutate({
      mutation: PROVIDERS_LIST,
      variables: { inn: data.inn },
      update: (store, { data }) => {
        commit('providersList', data)
      }
    })
  },
  async expiredTasks ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: EXPIRED_TASKS,
      variables: {
        id: context.$store.state.me._id,
        today: new Date()
      },
      update: (store, { data }) => {
        commit('expiredTasks', data)
      }
    })
  },
  async unprocessedLeads ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: UNPROCESSED_LEADS,
      variables: {},
      update: (store, { data }) => {
        commit('unprocessedLeads', data)
      }
    })
  },
  async unprocessesAppeals ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: UNPROCESSES_APPEALS,
      update: (store, { data }) => {
        commit('unprocessesAppeals', data)
      }
    })
  },
  async unprocessesSupports ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: UNPROCESSES_SUPPORTS,
      update: (store, { data }) => {
        commit('unprocessesSupports', data)
      }
    })
  },
  async allGroups ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: ALL_GROUPS,
      update: (store, { data }) => {
        commit('allGroups', data)
      }
    })
  },
  async allHoldings ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: ALL_HOLDINGS,
      update: (store, { data }) => {
        commit('allHoldings', data)
      }
    })
  },
  async allUsers ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: ALL_USERS,
      update: (store, { data }) => {
        commit('allUsers', data)
      }
    })
  },
  async allTasks ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: ALL_TASKS,
      variables: {
        id: context.$store.state.me._id
      },
      update: (store, { data }) => {
        commit('allTasks', { data, isMore: false })
      }
    })
  },
  async allTags ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: ALL_TAGS,
      update: (store, { data }) => {
        commit('allTags', data)
      }
    })
  },
  async allLeads ({ commit }, { context, variables, isMore }) {
    await context.$apollo.mutate({
      mutation: ALL_LEADS,
      variables: {
        checked: variables.checked,
        filter: variables.filter,
        page: variables.page,
        perPage: variables.perPage,
        sortField: variables.sortField,
        sortOrder: variables.sortOrder
      },
      update: (store, { data }) => {
        commit('allLeads', { data, isMore })
      }
    })
  },
  async allMemos ({ commit }, { context, variables, isMore }) {
    await context.$apollo.mutate({
      mutation: ALL_MEMOS,
      variables: {
        filterStatus: variables.filterStatus,
        page: variables.page,
        perPage: variables.perPage,
        sortDate: variables.sortDate,
        username: variables.username,
        periodStart: variables.periodStart,
        periodEnd: variables.periodEnd
      },
      update: (store, { data }) => {
        commit('allMemos', { data, isMore })
      }
    })
  },
  async allLeadsKanban ({ commit }, { context, variables, isMore }) {
    let leads = []
    let meta = []
    let queries = context.$store.state.leadStatusListClear.map(status => {
      let filter = JSON.parse(JSON.stringify(variables.filter))
      filter.status = status.value
      return context.$apollo.mutate({
        mutation: ALL_LEADS,
        variables: {
          filter: filter,
          page: variables.page,
          perPage: variables.perPage,
          sortField: variables.sortField,
          sortOrder: variables.sortOrder
        },
        update: (store, { data }) => {
          leads.push(...data.allLeads)
          meta.push({
            category: filter.status,
            count: data._allLeadsMeta
          })
        }
      })
    })
    await Promise.all(queries).then(() => {
      commit('allLeadsKanban', { leads, isMore, meta })
    })
  },
  async allProjects ({ commit }, { context, variables, isMore }) {
    await context.$apollo.mutate({
      mutation: ALL_PROJECTS,
      variables: {
        filter: variables.filter,
        page: variables.page,
        perPage: variables.perPage,
        sortField: variables.sortField,
        sortOrder: variables.sortOrder
      },
      update: (store, { data }) => {
        commit('allProjects', { data, isMore })
      }
    })
  },
  async allTours ({ commit }, context) {
    await context.$apollo.query({
      query: TOURS,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      commit('allTours', data)
    })
  },
  async lastEvents ({ commit }, context) {
    await context.$apollo.query({
      query: LAST_EVENTS,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      commit('lastEvents', data)
    })
  },
  async leadStatusList ({ commit }, context) {
    await context.$apollo.mutate({
      mutation: LEAD_STATUS_LIST,
      update: (store, { data }) => {
        commit('leadStatusList', data)
      }
    })
  },
  async me ({ commit }, context) {
    await context.$apollo.query({
      query: ME,
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      commit('me', data)
    })
  },
  async updateLead ({ commit }, { context, data }) {
    await context.$apollo.mutate({
      mutation: UPDATE_LEAD,
      variables: {
        input: data
      }
    }).catch(error => {
      _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
        context.$notify({
          group: 'lsg-notify',
          text: item
        })
      })
    })
  },
  async updateProject ({ commit }, { context, data }) {
    await context.$apollo.mutate({
      mutation: UPDATE_PROJECT,
      variables: data
    }).catch(error => {
      _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
        context.$notify({
          group: 'lsg-notify',
          text: item
        })
      })
    })
  },
  async fetchLead ({ commit }, { context, data }) {
    await context.$apollo.mutate({
      mutation: LEAD,
      variables: data,
      update: (store, { data }) => {
        commit('lead', data)
      }
    }).catch(error => {
      _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
        context.$notify({
          group: 'lsg-notify',
          text: item
        })
      })
    })
  },
  async checkProject ({ commit }, { context, data }) {
    await context.$apollo.mutate({
      mutation: CHECK_PROJECT,
      variables: data,
      update: (store, { data }) => {
        commit('checkProject', data)
      }
    }).catch(error => {
      _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
        context.$notify({
          group: 'lsg-notify',
          text: item
        })
      })
    })
  },
  async guarantor ({ commit }, { context, data }) {
    await context.$apollo.mutate({
      mutation: GUARANTOR,
      variables: data,
      update: (store, { data }) => {
        commit('guarantor', data)
      }
    }).catch(error => {
      _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
        context.$notify({
          group: 'lsg-notify',
          text: item
        })
      })
    })
  },
  async updateTask ({ commit }, { context, data }) {
    await context.$apollo.mutate({
      mutation: UPDATE_TASK,
      variables: {
        input: {
          id: data.id,
          is_completed: data.is_completed,
          complete_till_at: data.complete_till_at,
          task_type: data.task_type
        }
      }
    }).catch(error => {
      _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
        context.$notify({
          group: 'lsg-notify',
          text: item
        })
      })
    })
  },
  setAuthToken ({ commit }, { type, token }) {
    commit('setAuthToken', token)
  },

  allDirectories ({ commit }) {
    commit('allDirectories')
  },

  removeAuthToken ({ commit }) {
    commit('removeAuthToken')
  },

  setSideBarState ({ commit }, { value }) {
    commit('setSideBarState', value)
  },

  showOverlay ({ commit }, { show, text }) {
    commit('showOverlay', { show, text })
  },

  hideOverlay ({ commit }) {
    commit('hideOverlay')
  },

  switchShowAddTradeModal ({ commit }) {
    commit('switchShowAddTradeModal')
  },

  switchShowCloseTradeModal ({ commit }) {
    commit('switchShowCloseTradeModal')
  },

  switchShowDeleteTradeModal ({ commit }) {
    commit('switchShowDeleteTradeModal')
  },

  switchShowDeleteTaskModal ({ commit }) {
    commit('switchShowDeleteTaskModal')
  }
}
