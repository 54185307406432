import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions from './actions'
import data from './data'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authToken: localStorage.getItem('apollo-token') || '',
    sideBarState: localStorage.getItem('sidebar-state') || 'expanded',
    showAddTradeModal: false,
    showCloseTradeModal: false,
    showDeleteTradeModal: false,
    overlayShow: false,
    overlayText: '',
    allTasks: [],
    allHoldings: [],
    buyoutModalToggle: false,
    checkGuarantor: null,
    isEmpty: true,
    faqMessage: '',
    checkProject: {},
    calcModal: false,
    smsModal: false,
    companyForm: null,
    contactForm: null,
    faqNotification: false,
    allFaqResponsible: [],
    activeTheme: null,
    checkTimer: false,
    partnerInfo: {},
    step: 1,
    expiredTasks: null,
    diadocDocuments: null,
    unprocessedLeads: null,
    unprocessesSupports: null,
    unprocessesAppeals: null,
    applyData: null,
    allLeads: [],
    // allMemos: [],
    allLeadsKanban: [],
    allGroups: [],
    _allLeadsMeta: 0,
    trades: [],
    cabinet: [],
    checkedLeads: [],
    checkedTasks: [],
    checkedCompanies: [],
    checkedDirectories: [],
    checkedContacts: [],
    checkedContracts: [],
    checkedCandidates: [],
    allProjects: [],
    _allProjectsMeta: 0,
    _allDoTasksMeta: 0,
    lastEvents: [],
    allTours: [],
    loadedPages: [], // загруженные страницы (для туров)
    viewedTours: [], // id просмотренных туров
    leadStatusListClear: [],
    leadStatusList: [],
    showTourMenu: false,
    allDirectories: [],
    allSubdivisions: [],
    allDepartments: [],
    showEmployee: false,
    employeeData: null,
    showDirectoryMenu: false,
    pageTourSteps: [],
    data: data,
    isHiddenHeadAndSideBar: false,
    tv: {
      employee: null,
      notification: false
    },
    contactsShowFilter: true,
    checked: false,
    managerDesktop: false,
    showManagerDesktopMenu: false,
    filters: {
      contacts: {
        page: 0,
        perPage: 50,
        sort: {
          field: 'date',
          order: 'desc'
        },
        filter: {
          q: '',
          name: '',
          phone: '',
          email: '',
          tags: [],
          responsible_user_id: null,
          group_id: [],
          status: ''
        }
      },
      companies_and_contacts: {
        page: 0,
        perPage: 50,
        sort: {
          field: 'date',
          order: 'desc'
        },
        filter: {
          name: '',
          phone: '',
          email: ''
        }
      },
      partners: {
        page: 0,
        perPage: 50,
        sort: {
          field: 'fullname',
          order: 'desc'
        }
      }
    },
    loadMoreTasks: false,
    loadMoreTrades: false,
    loadMoreContracts: false,
    isCalling: false,
    call: {
      company: {},
      contacts: [],
      leads: [],
      phone: null,
      workphone: null
    },
    allRoles: [
      {
        title: 'Клиент',
        role: 'partner'
      },
      {
        title: 'Менеджер',
        role: 'manager'
      },
      {
        title: 'Руководитель',
        role: 'headmanager'
      },
      {
        title: 'Колл-центр',
        role: 'callcenter'
      },
      {
        title: 'Юристы, аналитики',
        role: 'backoffice'
      },
      {
        title: 'Служба безопасности и отдел сопровождения',
        role: 'debtcontrol'
      },
      {
        title: 'Сопровождение',
        role: 'sop'
      }
    ]
  },
  mutations: mutations,
  actions: actions,
  modules: {}
})
