let columns = [
  { hidden: false, label: 'Телефон', field: 'phone' },
  { hidden: false, label: 'Тип', field: 'task_type' },
  { hidden: false, label: 'Комментарий', field: 'text' },
  { hidden: true, label: 'Ответственный', field: 'responsible' },
  { hidden: false, label: 'Подразделение', field: 'group_id' },
  { hidden: false, label: 'Компания', field: 'company_id' },
  { hidden: true, label: 'Ответственный по компании', field: 'company_responsible' },
  { hidden: false, label: 'Сделать до', field: 'complete_till_at' },
  { hidden: false, label: 'Статус', field: 'status' },
  { hidden: false, label: 'Дата создания', field: 'createdAt' },
  { hidden: false, label: 'Дата изменения', field: 'updatedAt' },
  { hidden: false, label: 'Позвонить', field: 'call' }
]

let filters = [
  { label: 'Ответственный', field: 'responsible', hidden: false },
  { label: 'Подразделение', field: 'group', hidden: false },
  { label: 'Статус', field: 'status', hidden: false },
  { label: 'ИНН', field: 'inn', hidden: true }
]

if (localStorage.tasksColumns) {
  JSON.parse(localStorage.tasksColumns).forEach((col, i) => {
    if (columns[i].name === col.name) {
      columns[i].hidden = !col.show
    }
  })
}

if (localStorage.tasksFilters) {
  JSON.parse(localStorage.tasksFilters).forEach((filter, i) => {
    if (filters[i].name === filter.name) {
      filters[i].hidden = !filter.show
    }
  })
}

export default {
  page: 0,
  perPage: 50,
  showFilter: true,
  filter: {
    q: '',
    responsible_user_id: null,
    status: 'uncompleted',
    complete_till_at: null,
    group_id: [],
    is_completed: false,
    call_center: false
  },
  view: 'table',
  deleteModal: false,
  columnModal: false,
  sort: {
    field: 'createdAt',
    order: 'desc'
  },
  sort2: null,
  mobileModal: {
    show: false,
    showInfo: true,
    data: null
  },
  isSwipeTasks: false,
  sortDate: null,
  columns: columns,
  filters: filters
}
