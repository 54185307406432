let columns = [
  {
    label: 'Статус',
    field: 'status',
    hidden: false
  },
  {
    label: '№ проекта',
    field: 'projectNumber',
    hidden: false
  },
  {
    label: 'Договор',
    field: 'contract',
    hidden: false
  },
  {
    label: 'Стадия',
    field: 'stage',
    hidden: false
  },
  {
    label: 'Дата',
    field: 'date',
    hidden: false
  },
  {
    label: 'Подразделение',
    field: 'group_id',
    hidden: false
  },
  {
    label: 'Компания',
    field: 'company_id',
    hidden: false
  },
  {
    label: 'Менеджер',
    field: 'responsible_user_id',
    hidden: false
  },
  {
    label: 'Создатель',
    field: 'creator',
    hidden: true
  }
]

let filters = [
  { label: 'Статус', field: 'status', hidden: false },
  { label: 'Ответственный', field: 'responsible', hidden: false },
  { label: 'Подразделение', field: 'group', hidden: false },
  { label: 'Теги', field: 'tags', hidden: false },
  { label: 'Создатель', field: 'creator', hidden: false }
]

if (localStorage.tradeColumns) {
  JSON.parse(localStorage.tradeColumns).forEach((col, i) => {
    if (columns[i].name === col.name) {
      columns[i].hidden = !col.show
    }
  })
}

if (localStorage.tradeFilters) {
  JSON.parse(localStorage.tradeFilters).forEach((filter, i) => {
    if (filters[i].name === filter.name) {
      filters[i].hidden = !filter.show
    }
  })
}

export default {
  checked: false,
  page: 0,
  perPage: 50,
  isLoading: true,
  showFilter: true,
  sort: {
    field: 'createdAt',
    order: 'desc'
  },
  filter: {
    st: null,
    status: null,
    responsible_user_id: null,
    created_by: null,
    group_id: [],
    createdAt: null,
    updatedAt: null,
    tags: [],
    callCenter: false,
    createdBy: false
  },
  view: 'table',
  sort2: 'month',
  sortDate: null,
  columnModal: false,
  columns: columns,
  mainDataModal: false,
  contactDataModal: false,
  checkTradeModal: false,
  loadCheckTradeData: false,
  checkGuarantorModal: false,
  guarantorData: null,
  filters: filters,
  contactDataModalProps: {
    companyId: null,
    data: null,
    type: null
  },
  companyDataModal: false,
  companyDataModalProps: {
    elementId: null,
    data: null,
    elementType: null,
    isCanceled: null
  },
  checkSuccessModal: false,
  checkTradeClose: false,
  calcModal: false,
  calcDataModalProps: {
    leadId: null,
    type: null,
    header: null,
    data: null
  },
  fullCalcModal: false,
  fullCalcDataModalProps: {
    leadId: null,
    type: null,
    header: null,
    data: null
  },
  docsModal: false,
  status: null,
  rerenderKey: 0,
  rerenderCalcKey: 0
}
